<template>
    <v-card class="ma-0 pa-0 chart" flat>
        <v-card-text class="ma-0 pa-0">
            <v-chart class="ma-0 pa-0 chart" :key="updateKey" :option="option" />
        </v-card-text>
    </v-card>
</template>
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart } from "echarts/charts";
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    ToolboxComponent,
    GridComponent,
    MarkLineComponent,
    MarkPointComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { max } from "zrender/lib/core/vector";

use([
    CanvasRenderer,
    LineChart,
    BarChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    ToolboxComponent,
    GridComponent,
    MarkLineComponent,
    MarkPointComponent
]);

export default {
    props: ['data', 'defaultChart', 'labels', 'legend', 'title', 'textColor', 'xAxisLabel', 'yAxisLabel', 'color', 'updateKey'],
    components: {
        VChart
    },
    mounted(){
        console.log("updateKey",this.updateKey)
    },
    provide: {
        // [THEME_KEY]: "dark"
    },
    data() {
        return {
            option: {
                title: {
                    text: this.title,
                    show: true,
                    textStyle: {
                        color: this.textColor
                    },
                    bottom: 0,
                    left: 'center'
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: this.legend,
                    textStyle: {
                        color: this.textColor
                    },
                },
                toolbox: {
                    show: true,
                    feature: {
                        magicType: { type: ['line'] },
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    data: this.labels,
                    interval: 0,
                    nameLocation: "middle",
                    name: this.xAxisLabel,
                    nameGap: 25
                },
                yAxis: {
                    type: 'value',
                    nameLocation: "middle",
                    splitLine: {
                        show: false
                    },
                    name: this.yAxisLabel,
                    nameGap: 50
                },
                series: this.data
                // [
                //     {
                //         data: this.data,
                //         type: this.defaultChart ?? 'bar',
                //         color: this.color ?? 'grey',
                //         smooth: true,
                //         showInLegend: true,
                //         name: 'Containers'
                //     },
                //     {
                //         data: this.dataset2,
                //         type: 'line',
                //         color: this.dataset2Color ?? 'grey',
                //         smooth: true,
                //         showInLegend: true,
                //         name: 'Shipments'
                //     },

                // ]
            }
        };
    }
};
</script>
  
<style scoped>
.chart {
    display: flex;
    flex-flow: column;
    height: 41vh;
    min-height: 41vh;
}
</style>